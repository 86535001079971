/* eslint-disable react-refresh/only-export-components */ // FIXME
import React, { CSSProperties, forwardRef, HTMLAttributes } from "react"
import styled, { css, useTheme } from "styled-components"

// to find the one you need, look here: https://iconscout.com/unicons/explore/solid
import {
  UisExclamationCircle,
  UisExclamationTriangle,
  UisCheckCircle,
  UisTimesCircle,
  UisRocket,
} from "@iconscout/react-unicons-solid"

// to find the one you need, look here: https://iconscout.com/unicons/explore/line
import {
  UilAnalysis,
  UilAnalytics,
  UilAngleDoubleRight,
  UilAngleDown,
  UilAngleLeft,
  UilAngleRight,
  UilAngleUp,
  UilArrow,
  UilArrowDown,
  UilArrowLeft,
  UilArrowRight,
  UilArrowUp,
  UilBan,
  UilBell,
  UilBoltAlt,
  UilBooks,
  UilCalendarAlt,
  UilCheck,
  UilCheckCircle,
  UilClipboard,
  UilCloudUpload,
  UilCloudBlock,
  UilCloudCheck,
  UilCloudTimes,
  UilCloudQuestion,
  UilCog,
  UilComment,
  UilCommentQuestion,
  UilDatabase,
  UilDirection,
  UilEditAlt,
  UilEllipsisV,
  UilEnvelopeAlt,
  UilExclamationCircle,
  UilExclamationTriangle,
  UilExternalLinkAlt,
  UilFileAlt,
  UilFileSearchAlt,
  UilFilesLandscapes,
  UilFilter,
  UilFlask,
  UilGameStructure,
  UilGraphBar,
  UilHistory,
  UilHome,
  UilLinkAlt,
  UilPlay,
  UilPlayCircle,
  UilPresentationPlay,
  UilPlus,
  UilQuestion,
  UilQuestionCircle,
  UilRefresh,
  UilSave,
  UilSchedule,
  UilScrollH,
  UilSearch,
  UilSignAlt,
  UilSignout,
  UilSitemap,
  UilSort,
  UilSortAmountDown,
  UilSortAmountUp,
  UilSpinner,
  UilSpinnerAlt,
  UilStopwatch,
  UilTicket,
  UilTimes,
  UilTimesCircle,
  UilToggleOn,
  UilTrashAlt,
  UilUser,
  UilKeyholeCircle,
  UilDocumentInfo,
  UilChartBar,
  UilFileNetwork,
  UilFileCheckAlt,
  UilFastMail,
} from "@iconscout/react-unicons"
import { theme } from "src/ui/themes/theme.ts"

export const sortFilterIconStyle: CSSProperties = {
  fontSize: "16px",
  marginRight: theme.spacing.horizontal.xxs,
}

const IconContainer = styled.span<{
  $paddingRight: string
  $lineHeight: string
  $small?: boolean
}>`
  ${({ theme, $small }) =>
    $small &&
    css`
      width: ${theme.spacing.horizontal.s};
      height: ${theme.spacing.horizontal.s};
    `}
  ${({ $lineHeight }) =>
    $lineHeight &&
    css`
      line-height: ${$lineHeight};
    `}
      ${({ $paddingRight }) =>
    $paddingRight &&
    css`
      padding-right: ${$paddingRight};
    `}
`

export const IconsRef = {
  analysis: UilAnalysis,
  analytics: UilAnalytics,
  arrow: UilArrow,
  arrowDown: UilArrowDown,
  arrowLeft: UilArrowLeft,
  arrowRight: UilArrowRight,
  arrowUp: UilArrowUp,
  arrows: UilScrollH,
  ban: UilBan,
  bolt: UilBoltAlt,
  books: UilBooks,
  calendar: UilCalendarAlt,
  check: UilCheck,
  checkCircle: UilCheckCircle,
  checkCircleSolid: UisCheckCircle,
  chevronDown: UilAngleDown,
  chevronLeft: UilAngleLeft,
  chevronRight: UilAngleRight,
  chevronDoubleRight: UilAngleDoubleRight,
  chevronUp: UilAngleUp,
  clipboard: UilClipboard,
  close: UilTimes,
  closeCircle: UilTimesCircle,
  closeCircleSolid: UisTimesCircle,
  cloudBlock: UilCloudBlock,
  cloudCheck: UilCloudCheck,
  cloudTimes: UilCloudTimes,
  cloudQuestion: UilCloudQuestion,
  cloudUpload: UilCloudUpload,
  comment: UilComment,
  commentQuestion: UilCommentQuestion,
  database: UilDatabase,
  edit: UilEditAlt,
  exclamationCircle: UilExclamationCircle,
  exclamationCircleSolid: UisExclamationCircle,
  exclamationTriangleSolid: UisExclamationTriangle,
  externalLink: UilExternalLinkAlt,
  file: UilFileAlt,
  files: UilFilesLandscapes,
  fileSearchAlt: UilFileSearchAlt,
  filter: UilFilter,
  flask: UilFlask,
  graphBar: UilGraphBar,
  hierarchy: UilGameStructure,
  history: UilHistory,
  home: UilHome,
  link: UilLinkAlt,
  mail: UilEnvelopeAlt,
  notifications: UilBell,
  play: UilPlay,
  playCircle: UilPlayCircle,
  plus: UilPlus,
  presentationPlay: UilPresentationPlay,
  question: UilQuestion,
  questionCircle: UilQuestionCircle,
  refresh: UilRefresh,
  rocket: UisRocket,
  save: UilSave,
  schedule: UilSchedule,
  scrollUp: UilDirection,
  schema: UilSitemap,
  search: UilSearch,
  settings: UilCog,
  signAlt: UilSignAlt,
  signout: UilSignout,
  sort: UilSort,
  sortAmountDown: UilSortAmountDown,
  sortAmountUp: UilSortAmountUp,
  spinner: UilSpinner,
  spinnerAlt: UilSpinnerAlt,
  stopwatch: UilStopwatch,
  threeDotsVertical: UilEllipsisV,
  ticket: UilTicket,
  timesCircle: UilTimesCircle,
  toggleOn: UilToggleOn,
  trash: UilTrashAlt,
  user: UilUser,
  warning: UilExclamationTriangle,
  keyholeCircle: UilKeyholeCircle,
  documentInfo: UilDocumentInfo,
  chartBar: UilChartBar,
  fileNetwork: UilFileNetwork,
  fileCheckAlt: UilFileCheckAlt,
  fastMail: UilFastMail,
}

export type IconTypes = keyof typeof IconsRef

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIconType(icon: any): icon is IconTypes {
  return typeof icon === "string" && icon in IconsRef
}

export interface IconProps extends HTMLAttributes<SVGElement>, Pick<CSSProperties, "display"> {
  name: IconTypes
  small?: boolean
  size?: string
  color?: string
  lineHeight?: string
  paddingRight?: string
  className?: string
  disabled?: boolean
  hasSiblingContent?: boolean
  ariaLabel?: string
  title?: string
  width?: string
  height?: string
}

const Icon = forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
  const { name, paddingRight, lineHeight, className, disabled, ariaLabel, title, small, ...rest } = props
  const {
    colors: {
      neutralColorPalette: {
        backgroundsAndBorders: { gxBorder },
      },
    },
  } = useTheme()

  return (
    <IconContainer
      ref={ref}
      role="img"
      $small={small}
      $paddingRight={paddingRight ?? ""}
      $lineHeight={lineHeight ?? ""}
      className={className}
      aria-label={ariaLabel ?? name}
      title={title}
    >
      {React.createElement(IconsRef[name], {
        ...rest,
        color: disabled ? gxBorder : rest.color,
      })}
    </IconContainer>
  )
})
Icon.displayName = "Icon"

export { Icon }
