export const SERVICE_NAME = "mercury-web"
export const DEBOUNCE_DELAY = 500

// Datetime formatting standards
export const APP_TIME_FORMAT: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "2-digit" }
export const APP_TIME_WITH_TIMEZONE_FORMAT: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "2-digit",
  timeZoneName: "short",
}
export const APP_24_HOUR_TIME_WITH_TIMEZONE_FORMAT: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "2-digit",
  timeZoneName: "short",
  hour12: false,
}
export const APP_24_HOUR_TIME_FORMAT: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "2-digit",
  hour12: false,
}

export const APP_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
}
export const APP_DATE_WITH_YEAR_FORMAT: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
}
export const APP_DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "2-digit",
  second: "numeric",
  year: "numeric",
  day: "2-digit",
  month: "2-digit",
}

export const APP_CALENDAR_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
}

export const APP_CALENDAR_DATE_WITH_YEAR_FORMAT: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric",
}

export const POSTHOG_API_HOST = "https://posthog.greatexpectations.io"

// support links
export const SUPPORT_PORTAL_URL = "https://support.greatexpectations.io"
export const SUPPORT_EMAIL_URL = "mailto:support@greatexpectations.io"
export const DOCS_URL = "https://docs.greatexpectations.io/docs/cloud"
export const DISCOURSE_URL = "https://discourse.greatexpectations.io/c/cloud-support/17"
export const SLACK_WORKSPACE_URL = "https://app.slack.com/client/T7NEH00BA"
export const SLACK_BETA_SUPPORT_CHANNEL_URL = `${SLACK_WORKSPACE_URL}/C051D941XAL`
export const SET_UP_GX_CLOUD_URL = "https://docs.greatexpectations.io/docs/cloud/try_gx_cloud"
export const CREATE_DATA_ASSET_URL =
  "https://docs.greatexpectations.io/docs/cloud/data_assets/manage_data_assets#create-a-data-asset"
export const CONFIGURE_DATA_SOURCE_URL =
  "https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/connect_to_data_lp"
export const PYTHON_QUICKSTART_URL = "https://docs.greatexpectations.io/docs/cloud/connect/connect_python"
export const CONNECT_TO_CLOUD_URL = "https://docs.greatexpectations.io/docs/cloud/connect/connect_lp"
export const TRY_GX_CLOUD_URL = "https://docs.greatexpectations.io/docs/cloud/try_gx_cloud"
export const AGENT_DEPLOY_GUIDE_URL = "https://docs.greatexpectations.io/docs/cloud/deploy_gx_agent"
export const PRICING_URL = "https://greatexpectations.io/pricing"

// Toast message duration
export const MESSAGE_DURATION_SECONDS = 4
export const QUICK_MESSAGE_DURATION = 2

// Toast message placement
export const MESSAGE_PLACEMENT_FROM_TOP = 50

// Notification duration
export const NOTIFICATION_WITH_LINK_DURATION_SECONDS = 10 // notifications with links should last longer
export const NOTIFICATION_INFINITE_DURATION_SECONDS = 0 // Infinite duration, requires manual dismissal
export const EXPECTATION_CHANGE_LOG_LIMIT = 100 // Number of previous changes to show in the expectation change history
export const DELETE_MODAL_WIDTH = 550

// Schema change constants
export const AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT = 100
export const AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR = `At this time we're unable to onboard and automatically generate expectations for more than ${AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT} assets. If you need to generate more than ${AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT} assets & expectations, please reach out to GX Support.`

/**
 * The Windowed types and items live in this file
 * because they need to be accessible from both
 * the core application code as well as the
 * expectation-catalog.ts codegen script.
 */
export const WindowedMinMaxExpectations = [
  "expect_column_max_to_be_between",
  "expect_column_mean_to_be_between",
  "expect_column_median_to_be_between",
  "expect_column_min_to_be_between",
  "expect_column_proportion_of_unique_values_to_be_between",
  "expect_column_stdev_to_be_between",
  "expect_column_sum_to_be_between",
  "expect_column_unique_value_count_to_be_between",
  "expect_table_column_count_to_be_between",
  "expect_table_row_count_to_be_between",
] as const

export const WindowedCompletenessExpectations = [
  "expect_column_values_to_be_null",
  "expect_column_values_to_not_be_null",
] as const

export type WindowedMinMaxExpectation = (typeof WindowedMinMaxExpectations)[number]
export type WindowedCompletenessExpectation = (typeof WindowedCompletenessExpectations)[number]
export type WindowedExpectation = WindowedMinMaxExpectation | WindowedCompletenessExpectation

export type WindowedExpectationTemplates = {
  [K in WindowedExpectation | string]: (input: { originalTemplate: string; preposition: string }) => string
}

export const END_OF_CONDITION_STRING = ", then "

/**
 * returns row condition if exists and `$column` if exists at beginning of string
 * @param originalTemplate - the original template string
 * @returns the prefix of the template
 */
export function getConditionPrefix(originalTemplate: string): string {
  const columnIndex = originalTemplate.indexOf("$column")
  if (columnIndex !== -1) return originalTemplate.slice(0, columnIndex + 7)

  const endOfConditionIndex = originalTemplate.indexOf(END_OF_CONDITION_STRING)
  if (endOfConditionIndex !== -1) return originalTemplate.slice(0, endOfConditionIndex + 7)

  return ""
}

export const windowedExpectationTemplates: WindowedExpectationTemplates = {
  expect_column_max_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} maximum value must be ${preposition} $offset of the $constraint_fn maximum of the last $range runs`,
  expect_column_mean_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} mean value must be ${preposition} $offset of the $constraint_fn mean of the last $range runs`,
  expect_column_median_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} median value must be ${preposition} $offset of the $constraint_fn median of the last $range runs`,
  expect_column_min_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} minimum value must be ${preposition} $offset of the $constraint_fn minimum of the last $range runs`,
  expect_column_proportion_of_unique_values_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} proportion of unique values must be ${preposition} $offset of the $constraint_fn of the proportions of the last $range runs`,
  expect_column_stdev_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} standard deviation must be ${preposition} $offset of the $constraint_fn standard deviations of the last $range runs`,
  expect_column_sum_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} sum must be ${preposition} $offset of the $constraint_fn sum of the last $range runs`,
  expect_column_unique_value_count_to_be_between: ({ preposition, originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} unique value count must be ${preposition} $offset of the $constraint_fn unique value counts of the last $range runs`,
  expect_table_column_count_to_be_between: ({ originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} Must have $offset of the $constraint_fn column counts of the last $range runs`,
  expect_table_row_count_to_be_between: ({ originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} Must have $offset of the $constraint_fn row counts of the last $range runs`,

  // in some cases it's possible the completeness renderers will already have $column, so check to make sure we're not duplicating
  expect_column_values_to_be_null: ({ originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} ${originalTemplate.indexOf("$column") === -1 ? "$column " : ""}must be within $offset of the $constraint_fn null percentage of the last $range runs`,
  expect_column_values_to_not_be_null: ({ originalTemplate }) =>
    `${getConditionPrefix(originalTemplate)} ${originalTemplate.indexOf("$column") === -1 ? "$column " : ""}must be within $offset of the $constraint_fn not-null percentage of the last $range runs`,
}
